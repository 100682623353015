import { eUserRole } from './../_generated/enums';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot
} from '@angular/router';

import { StorageService } from '@Workspace/services';
import { AuthService } from '@Workspace/auth';

import { Constants } from '@Workspace/common';

@Injectable()
export class SignInGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private authService: AuthService,
        private storageService: StorageService,
        private router: Router,
    ) {}

    private route: Route;

    public canLoad(route: Route) {
        this.route = route;

        return this.canActivatePage(route?.data?.roles ?? []);
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        return this.canActivatePage(route?.data?.roles ?? []);
    }

    public canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        return this.canActivatePage(route?.data?.roles ?? []);
    }

    private canActivatePage(roles: eUserRole []): boolean {
        if (this.authService.isLogged) {
            if(!roles || roles.indexOf(this.authService.userSettings.role) != -1){
                return true;
            }
        } else {
            this.storageService.set(
                Constants.LAST_URL_KEY,
                window.location.hash.substr(2)
            );

            this.router.navigate(['/auth/sign-in']);
            return false;
        }
    }
}
