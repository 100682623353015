import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import {
    ICustomSelectItem,
    IGridColumnComposition,
    IGridOptions,
    IPagingResult,
} from '@Workspace/interfaces';
import { cloneDeep } from 'lodash';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Md5 } from 'ts-md5';

import { decode } from 'html-entities';

import { Constants } from './constants';
import * as clientSideEnums from './enums';

export class Functions {
    public static MustBeLess(
        firstControlName: string,
        secondControlName: string
    ): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const dateFrom = control.get(firstControlName).value;
            const dateTo = control.get(secondControlName).value;
            return dateFrom && dateTo && dateFrom > dateTo
                ? { startDate: { value: control.value } }
                : null;
        };
    }

    public static getExpiration(
        part: 'days' | 'hours' | 'minutes' | 'seconds',
        num?: number,
        date = new Date()
    ): Date {
        if (!num || num < 1) {
            num = 1;
        }

        switch (part) {
            case 'seconds':
                date.setSeconds(date.getSeconds() + num);
                break;
            case 'minutes':
                date.setMinutes(date.getMinutes() + num);
                break;
            case 'hours':
                date.setHours(date.getHours() + num);
                break;
            case 'days':
            default:
                date.setDate(date.getDay() + num);
                break;
        }

        return date;
    }

    public static isDate(text: any): boolean {
        if (text == null) {
            return;
        }

        if (text instanceof Date) {
            return true;
        }

        const isNumber = Functions.isNumber(text);
        if (
            new Date(text).toString() !== 'Invalid Date' &&
            !!new Date(text) &&
            !/([a-zA-Z]{3})/.exec(text) &&
            (!isNumber || text > 2674800000) &&
            typeof text === 'string' &&
            text.indexOf('%') === -1
        ) {
            return true;
        } else {
            return false;
        }
    }

    public static isNumber(text: any): boolean {
        return parseFloat(text).toString() === text.toString();
    }

    public static dateToUserTimeZone(
        dateString: string,
        timeZone: string,
        formatString?: string
    ): string {
        if (!dateString) {
            return '';
        }

        if (!formatString) {
            formatString = Constants.DEFAULT_FORMAT_DATETIME;
        }

        const date = moment.utc(dateString);
        let dateInUserTimeZone = date;

        if (!!timeZone) {
            dateInUserTimeZone = moment(date.valueOf()).tz(timeZone);
        }

        return dateInUserTimeZone.format(formatString);
    }

    public static sortByTimestamp(a: any, b: any): number {
        const x =
            a.dateCreatedTimestamp > 0
                ? new Date(a.dateCreatedTimestamp)
                : new Date(a.intervalStartTimestamp);
        const y =
            b.dateCreatedTimestamp > 0
                ? new Date(b.dateCreatedTimestamp)
                : new Date(b.intervalStartTimestamp);

        return x.getTime() - y.getTime();
    }

    public static parseToSelectList<T>(
        data: T[],
        labelExp: (property: T) => any,
        valueExp: (property: T) => any
    ): { label: string; value: any }[] {
        return data.map((item) => {
            return {
                label: labelExp(item),
                value: valueExp(item),
            };
        });
    }

    public static parseToSelectListObservable<T>(
        dataObservable: Observable<T[]>,
        labelExp: (property: T) => any,
        valueExp: (property: T) => any
    ): Observable<{ label: string; value: any }[]> {
        return dataObservable.pipe(
            map((data) => {
                return data.map((item) => {
                    return {
                        label: labelExp(item),
                        value: valueExp(item),
                    };
                });
            })
        );
    }

    public static nameOf<T>(propertyExpression: (property: T) => any): string {
        const functionString = propertyExpression.toString();
        const start = functionString.lastIndexOf('.') + 1;
        const end = functionString.lastIndexOf(';');

        return functionString.substr(start, end - start);
    }

    public static iterateEnum(
        enumType,
        optional?: { label: string; value: any },
        orderByLabel?: boolean,
        ...exludedValues: any[]
    ): { label: string; value: any }[] {
        if (!enumType) {
            return null;
        }

        let result = this.getNamesAndValues(enumType);
        result.forEach((e) => (e.label = this.toSentenceCase(e.label).trim()));

        if (
            !!exludedValues &&
            Array.isArray(exludedValues) &&
            exludedValues.length > 0
        ) {
            result = result.filter(
                (item) => exludedValues.indexOf(item.value) === -1
            );
        }

        if (!!optional) {
            result = [optional, ...result];
        }

        if (!!orderByLabel) {
            result = result.sort((a, b) => {
                const x = a.label.toLowerCase();
                const y = b.label.toLowerCase();
                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
                return 0;
            });
        }

        return result;
    }

    public static toSentenceCase(text: string): string {
        return text
            .replace(/([A-Z][a-z]|^[a-z]?)/g, ' $1')
            .replace(/[_]/g, ' ');
    }

    public static getNamesAndValues(obj): { label: string; value: any }[] {
        return this.getStringObjectValues(obj).map((n) => {
            return { label: n, value: obj[n] };
        });
    }

    public static getStringObjectValues(obj) {
        return this.getAllObjectValues(obj).filter(
            (v) => typeof v === 'string'
        ) as string[];
    }

    public static getAllObjectValues(obj): (number | string)[] {
        return Object.keys(obj).map((key) => obj[key]);
    }

    static base64ToBlob(
        base64: string,
        contentType: string,
        sliceSize: number = 512
    ) {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    public static cloneObject(obj: any) {
        const cln = cloneDeep(obj);

        return cln;
    }

    public static isWindow() {
        return !window.frameElement;
    }

    public static getGravatarPicture(email: string): string {
        return (
            'https://www.gravatar.com/avatar/' + Md5.hashStr(email) + '?d=mp'
        );
    }

    public static composeGridOptions(
        options: IGridOptions,
        sortField: string,
        data: IPagingResult | string,
        isSimpleview: boolean = true,
        columns?: IGridColumnComposition[],
        isOData: boolean = true
    ): IGridOptions {
        options.sortField = sortField;
        options.isSimpleView = isSimpleview;
        options.isOData = isOData;

        if (typeof data === 'string') {
            options.data = data;
        } else {
            options.data = data;
        }

        if (!!columns) {
            options.columns = [...columns];
        }

        return options;
    }

    public static isFieldInvalid(formControl: FormControl): boolean {
        return (
            formControl.invalid && (formControl.touched || formControl.dirty)
        );
    }

    public static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                let r = (Math.random() * 16) | 0;
                let v = c === 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    public static onScrollExternal(
        references: any, //IScrollingReferences
        onScroll: () => void
    ) {
        if (!!references) {
            references.dialogs?.forEach((dialog) => {
                let x = dialog._onScrollBody;
                dialog._onScrollBody = () => {
                    x();
                    onScroll();
                };
            });

            references.divs?.forEach((div) =>
                div.addEventListener('scroll', onScroll)
            );

            references.stepWizards?.forEach((x) => {
                let panels: HTMLDivElement =
                    x.stepWizard.el.nativeElement.querySelector(
                        '.ui-tabview-panels'
                    );
                let tabRef = panels.children[x.activeIndex]
                    .firstElementChild as HTMLDivElement;
                tabRef.addEventListener('scroll', onScroll);
            });

            references.tables?.forEach((table) => {
                let tableRef: HTMLDivElement =
                    table.containerViewChild.nativeElement.querySelector(
                        '.ui-table-wrapper'
                    );
                tableRef.addEventListener('scroll', onScroll);
            });

            references.virtualScrollers?.forEach((vs) => {
                let x = vs.onScrollBody;
                vs.onScrollBody = () => {
                    x();
                    onScroll();
                };
            });
        }
    }

    public static removeExternalOnScroll(
        references: any, // IScrollingReferences
        onScroll: () => void
    ) {
        if (!!references) {
            references.dialogs?.forEach((dialog) => {
                let x = dialog._onScrollBody;
                dialog._onScrollBody = () => x();
            });

            references.divs?.forEach((div) =>
                div.removeEventListener('scroll', onScroll)
            );

            references.stepWizards?.forEach((x) => {
                let panels: HTMLDivElement =
                    x.stepWizard.el.nativeElement.querySelector(
                        '.ui-tabview-panels'
                    );
                let tabRef = panels.children[x.activeIndex]
                    .firstElementChild as HTMLDivElement;
                tabRef.removeEventListener('scroll', onScroll);
            });

            references.tables?.forEach((table) => {
                let tableRef: HTMLDivElement =
                    table.containerViewChild.nativeElement.querySelector(
                        'div.ui-table-wrapper'
                    );
                tableRef.removeEventListener('scroll', onScroll);
            });

            references.virtualScrollers?.forEach((vs) => {
                let x = vs.onScrollBody;
                vs.onScrollBody = () => x();
            });
        }
    }

    public static sortOptions(
        arr: any[],
        sortBy: string,
        caseSensitive = false
    ) {
        return caseSensitive
            ? arr.sort((a, b) => {
                  let a1 = a[sortBy] as string,
                      b1 = b[sortBy] as string;
                  return a1.toLowerCase() < b1.toLowerCase() ? -1 : 1;
              })
            : arr.sort((a, b) => (a[sortBy] < b[sortBy] ? -1 : 1));
    }

    /**
     * @description parses data into JSON data object
     * @param data data should not be null or empty string since they lead to console errors
     * @public
     * @static
     * @yields JSON data obj
     */
    public static parseData(data): any {
        return typeof data == 'string' ? JSON.parse(data) : data;
    }

    /**
     * @description copies text to clipboard
     * @param text of type string
     * @public
     * @static
     */
    public static copyTextToClipboard(text: string) {
        let tempInput = document.createElement('textarea');
        tempInput.style.position = 'absolute';
        tempInput.style.left = tempInput.style.top = '-1000px';
        tempInput.value = text;

        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
    }

    /**
     * @description disbles shift key on keyboardevent and again dispatch the event on same target
     * @description uses {@link event.stopPropagation()} and {@link event.preventDefault()}
     * @param event {@link KeyboardEvent}
     */
    static disableShiftKeyOnKeyboardEvent(event: KeyboardEvent) {
        if (!!event.shiftKey) {
            event.stopPropagation();
            event.preventDefault();
            let newEvent: any = event;
            newEvent.shiftKey = false;
            event.target.dispatchEvent(
                new KeyboardEvent(newEvent.type, newEvent)
            );
        }
    }

    static getRichTextValueWithoutEmptySpace(value: string): string {
        return !!value
            ? decode(
                  value.replace(Constants.richTextEmptySpaceRegex, '')
              ).trim()
            : '';
    }
    public static getActionButtonLu(
        ...includedValues: number[]
    ): ICustomSelectItem[] {
        var enumKeys = Object.keys(clientSideEnums['eActionButton']);

        var enumData = enumKeys.slice(enumKeys.length / 2).map((x) => {
            if (includedValues.includes(clientSideEnums.eActionButton[x])) {
                let actionButton = clientSideEnums.eActionButtonName.get(
                    clientSideEnums.eActionButton[x]
                );

                return {
                    value: clientSideEnums.eActionButton[x],
                    label: x.replace(/\_/g, ' '),
                    icon: actionButton?.icon,
                    parent: !!actionButton?.parent
                        ? actionButton.parent !=
                          clientSideEnums.eActionButton[x]
                            ? this.getActionButtonLu(actionButton.parent)[0]
                            : {
                                  value: actionButton.parent,
                                  label: x.replace(/\_/g, ' '),
                                  icon: actionButton.icon,
                                  parent: null,
                              }
                        : null,
                } as ICustomSelectItem;
            }
        });

        return enumData.filter((x) => x != undefined);
    }
}
