<auth-layout [content]="is2FA ? twoStep : signIn" [isSignInPage]="!is2FA"></auth-layout>

<ng-template #signIn>
    <div class="login__container align-items-center">
        <img class="login__logo logo-light" src="assets/BullBlock_logotyp.svg" alt="Bull Block">
        <span class="login__logo__text">BULL BLOCK</span>

        <div class="login__form">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="p-fluid">
                    <div class="p-field">
                        <span class="p-float-label">
                            <input id="email" type="text" formControlName="email"
                            [ngClass]="{'ng-dirty': submitted && f.email.errors}" pInputText 
                            [tooltipDisabled]="!(f.email.invalid && (f.email.dirty || f.email.touched))" [pTooltip]="getEmailErrors(f.email.errors)" tooltipPosition="top"/>
                            <label for="email">Email</label>
                        </span>
                    </div>
                    <div class="p-field">
                        <span class="p-float-label">
                            <input id="password" type="password" formControlName="password" [ngClass]="{'ng-dirty': submitted && f.password.errors}" pInputText
                            [tooltipDisabled]="!(f.password.invalid && (f.password.dirty || f.password.touched))" [pTooltip]="getPasswordErrors(f.password.errors)" tooltipPosition="top"/>
                            <label for="password">Password</label>
                        </span>
                    </div>
                </div>
                <button pButton [disabled]="loading" type="submit" label="Login" [ngClass]=""></button>
            </form>
        </div>
        <a routerLink="/auth/forgot-password" class="login__link">Forgot Password?</a>
    </div>
</ng-template>

<ng-template #twoStep>
    <div class="login__container">

        <h2>2-Step Verification</h2>
        <div class="login__description">Use your device to log in to your account.</div>

        <div *ngIf="!this.registrationDetails.is2FARegistered">
            <h3>Register Authenticator</h3>
            <qr-code
                [value]="registrationDetails.qrCodeUri"
                errorCorrectionLevel="M"
                id="authenticator-qr-code"
            ></qr-code>
        </div>

        <h3>Enter verification code</h3>
        <div class="login__description">Get your code from the MS Authenticator app</div>

        <form [formGroup]="twoStepForm" (ngSubmit)="onTwoStepSubmit()">
            <div class="p-fluid">
                <div class="p-field">
                    <span class="p-float-label">
                        <input id="code" type="text" formControlName="code" [ngClass]="{'is-invalid': submitted && f2.code.errors}" pInputText 
                        [tooltipDisabled]="!(f2.code.invalid && (f2.code.dirty || f2.code.touched))" [pTooltip]="getCodeErrors(f2.code.errors)" tooltipPosition="top"/>
                        <label for="float-input">6-digit code</label>
                    </span>
                </div>
            </div>
            <button pButton [disabled]="loading" type="submit" label="Done" class="btn rc-primary-btn"></button>
        </form>
    </div>
</ng-template>