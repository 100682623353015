import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { eMessageSeverity } from '@Workspace/common';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class ToastService {
    constructor(private messageService: MessageService) {}
    add(
        severity: eMessageSeverity,
        message: string = '',
        detail: string = null
    ) {
        if (!message) {
            if (severity == eMessageSeverity.Error) {
                message =
                    'Sorry, an error has occurred while completing the action.';
            } else if (severity == eMessageSeverity.Success) {
                message = 'The operation succeeded!';
            } else {
                message = '';
            }
        }

        this.messageService.add({
            severity: severity.toLowerCase(),
            summary: message,
            detail: detail,
        });
    }

    addSuccess() {
        this.add(eMessageSeverity.Success);
    }

    addHttpError(error: HttpErrorResponse) {
        const errors = error.error?.Errors;

        if (
            (typeof errors !== typeof {} || Object.keys(errors).length === 0) &&
            !!error.error.Message
        )
            errors['errorMessage'] = [error.error?.Message];

        for (let key in errors) {
            this.add(eMessageSeverity.Error, errors[key]);
        }
    }
}