//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { eEnvironment } from './enums';
import { eSocialProvider } from './enums';
import { ePortfolioType } from './enums';
import { eUserRole } from './enums';
import { eEntityStatus } from './enums';

export interface ISampleResponseModel
{
	message: string;
	success: boolean;
	currentTime: string;
}
export interface IPagingResult
{
	totalCount: number;
	skip: number;
	take: number;
	data: any[];
}
export interface IPagingResultGeneric<TData> extends IPagingResult
{
	data: TData[];
}
export interface IApiSettings
{
	environment: eEnvironment;
	apiId: string;
	clientId: string;
	tenant: string;
	tenantId: string;
}
export interface IEnumDataAttribute
{
	name?: string;
	description?: string;
	icon?: string;
	colorReference?: string;
	alias?: string;
	enumLocalizationKey?: string;
	format?: string;
	code?: string;
	javaScriptFormat?: string;
	pCalendarFormat?: string;
	projectScheduleFormat?: string;
	shortName?: string;
	sortOrder?: number;
	isImmediate?: boolean;
	canOptOutOfNotification?: boolean;
}
export interface IErrorJs
{
	url: string;
	message: string;
	stack: string;
}
export interface IIdModelDto
{
	id: string;
}
export interface IChangePasswordModel
{
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
}
export interface ILoginModel
{
	email: string;
	password: string;
	secret2FA: string;
	code2FA: string;
	socialProvider: eSocialProvider;
	token: string;
}
export interface IRefreshTokenModel
{
	refreshToken: string;
}
export interface IResetPasswordModel
{
	userId: string;
	token: string;
	newPassword: string;
	confirmPassword: string;
}
export interface IUserEmailModel
{
	email: string;
}
export interface IBaseDto
{
}
export interface IAccountSecurityPolicyDto
{
	minLength: number;
	maxLength: number;
	minCountOfUppercaseLetters: number;
	minCountOfLowercaseLetters: number;
	minCountOfSpecialCharacters: number;
	minCountOfNumericCharacters: number;
	expirePasswords: boolean;
	expirePasswordAfterNumberOfDays: number;
	alertUsersOfExpiringPasswordsNumberOfDays: number;
	lockAfterNumberOfInvalidLoginAttempts: number;
	lockAccounts: boolean;
	allowUsersToChangePassword: boolean;
	allowSameAsUsername: boolean;
	empty: IAccountSecurityPolicyDto;
}
export interface IClientPreviewDto extends IBaseDto
{
	id: string;
	name: string;
	portfolioType: ePortfolioType;
	deposit: number;
	balance: number;
	cut: number;
	agentName: string;
	agentEmail: string;
	dateCreated: Date;
	dateEnd: Date;
}
export interface IDateTimeSettingsDto
{
	startDayOfWeek: number;
	timeFormat: number;
	dateFormat: number;
	timeFormatValue: string;
	dateFormatValue: string;
}
export interface IEmailCreateDto extends IBaseDto
{
	recipientEmail: string;
	senderEmail: string;
	module: string;
	category: string;
	content: string;
	subject: string;
}
export interface IEmailDto extends IBaseDto
{
	id: string;
	recipientEmail: string;
	senderEmail: string;
	status: number;
	module: string;
	category: string;
	content: string;
	subject: string;
	senderRunCount: number;
}
export interface IEmailStatusChangeDto extends IBaseDto
{
	emailId: string;
	newStatus: number;
	increaseRunCounter: boolean;
}
export interface IGraphDataDto extends IBaseDto
{
	xAxis: string[];
	yAxis: string[];
}
export interface IGridBaseFilterDto
{
	page: number;
	pageSize: number;
	sortOrder: number;
	sortField: string;
	searchText: string;
}
export interface ILookupDto<T> extends IBaseDto
{
	id: T;
	sortOrder: number;
	name: string;
	type: any;
}
export interface INetworkDto extends IBaseDto
{
	id: string;
	description: string;
	checkUrl: string;
}
export interface IPaymentDto extends IBaseDto
{
	id: string;
	userId: string;
	userFullName: string;
	name: string;
	portfolioType: ePortfolioType;
	amount: number;
	dateEnd: Date;
}
export interface IPreSignInDetailsDto
{
	secretKey: string;
	manualRegistrationKey: string;
	issuer: string;
	accountName: string;
	qrCodeUri: string;
	is2FARegistered: boolean;
	is2FAEnabled: boolean;
}
export interface IProfileUserDto extends IBaseDto
{
	id: string;
	fullName: string;
	email: string;
	passwordChangedOn: Date;
	is2FAEnabled: boolean;
	is2FASetupCompleted: boolean;
	timeZoneId: number;
}
export interface IReorderSimpleDto
{
	id: string;
	orderIndex: number;
}
export interface ITimeZoneDto extends IBaseDto
{
	id: number;
	name: string;
	utcOffSet: string;
	description: string;
	jsName: string;
}
export interface IUpdate2FADto
{
	secret: string;
	code: string;
}
export interface IUpdatedBySimpleDto
{
	id: string;
	fullName: string;
	avatarUrl: string;
	date: Date;
}
export interface IUserCreateDto extends IBaseDto
{
	username: string;
	email: string;
	fullName: string;
	password: string;
	confirmPassword: string;
	timeZoneId: number;
	role: number;
}
export interface IUserDto extends IBaseDto
{
	id: string;
	userName: string;
	fullName: string;
	email: string;
	phone: string;
	avatarUrl: string;
	passwordChangedOn: Date;
	isLocked: boolean;
	is2FAEnabled: boolean;
	timeZoneId: number;
	role: eUserRole;
	entityStatus: eEntityStatus;
}
export interface IUserPasswordResetDto extends IBaseDto
{
	id: string;
	token: string;
}
export interface IUserPropertiesDto
{
	id: string;
	email: string;
	fullName: string;
	role: eUserRole;
	isDarkTheme: boolean;
	dateTimeSettings: IDateTimeSettingsDto;
}
export interface IUserStatisticsDto extends IBaseDto
{
	lastLoggedIn: Date;
	successLogInsLastSevenDays: number;
	failedLogInsLastSevenDays: number;
}
export interface IGridParameters
{
	quickSearchValue: string;
	sortBy: string;
	sortDir: number;
	pageIndex: number;
	pageSize: number;
	allDataCount: number;
}
export interface IGridParametersUser extends IGridParameters
{
}
export interface IGridResponse<T>
{
	data: T[];
	parameters: IGridParameters;
}
