<header class="main-header">
    <div class="logo">
        <div class="logo-button" (click)="toggleMenu()">
            <img class="logo-image logo-light" src="assets/BullBlock_logotyp.svg" alt="BullBlock">
            <img class="logo-image-large logo-light" src="assets/BullBlock_logotyp.svg" alt="BullBlock">
            <span class="logo_text">BULL BLOCK</span>
        </div>
    </div>
    <nav class="main-nav">
        <ul class="nav-menu" *ngFor="let group of menuGroups">
            <ng-container *ngFor="let item of group">
                <li class="nav-item" [routerLinkActive]="['is-active']"
                [ngClass]="{ 'is-hidden': !item.isVisible() }">
                    <a *ngIf="!item.command" [routerLink]="item.dynamicUrl ? [item.dynamicUrl()] : [item.url]" [title]="item.label" class="nav-url">
                        <i class="bi {{item.icon}}"></i>
                        <span class="nav-title">{{item.label}}</span>
                    </a>

                    <button *ngIf="!!item.command" (click)="item.command()" [title]="item.label" class="nav-url">
                        <i class="bi {{item.icon}}"></i>
                        <span class="nav-title">{{item.label}}</span>
                    </button>
                </li>
            </ng-container>
        </ul>
    </nav>
</header>