import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '@Workspace/common';
import { eSocialProvider } from '@Workspace/_generated/enums';
import { Observable } from 'rxjs/internal/Observable';
import { SettingsService } from '../settings/settings.service';
import { StorageService } from '../storage/storage.service';

// import { AuthAdSevice } from './auth.ad.service';
import { AuthTokenService } from './auth.token.service';
import { IAuthService } from './iauth.service';

@Injectable()
export class AuthService implements IAuthService {
    private authService: IAuthService;

    constructor(
        private settingsService: SettingsService,
        private storageService: StorageService,
        private authTokenService: AuthTokenService,
        // private authAdService: AuthAdSevice
    ) {}

    async GetUserAccessTokenAsync(url?:string){
        return await this.authService.GetUserAccessTokenAsync(url);
    }

    get $userState() {
        return this.authService.$userState;
    }

    get userSettings() {
        return this.authService.userSettings;
    }

    get isLogged() {
        return this.authService.isLogged;
    }

    public async getIsAd() {
        return !!(await this.storageService.get(Constants.IS_AD_KEY));
    }

    private async setIsAd(isAdValue: boolean) {
        await this.storageService.set(Constants.IS_AD_KEY, isAdValue);
    }

    async reloadUserdataAsync() {
        await this.authService.reloadUserdataAsync();
    }

    async reloadUserSettingsAsync() {
        await this.authService.reloadUserSettingsAsync();
    }

    async initializeAsync() {
        const isAd = await this.getIsAd();

        if (isAd) {
            // this.authService = this.authAdService;
        } else {
            this.authService = this.authTokenService;
        }

        await this.authService.initializeAsync();
    }

    async login(username: string, password: string, secret: string, code: string): Promise<void> {
        await this.setIsAd(false);
        await this.initializeAsync();
        await this.authService.login(username, password, secret, code);
    }

    async loginSocial(socialProvider: eSocialProvider): Promise<void> {
        if(socialProvider == eSocialProvider.AzureAD){
            await this.setIsAd(true);
        } else {
            await this.setIsAd(false);
        }
        await this.initializeAsync();

        await this.authService.loginSocial(socialProvider);
    }

    async logOut(): Promise<void> {
        await this.authService.logOut();
    }

    authorizeRequest(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return this.authService.authorizeRequest(request, next);
    }
}
