import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from '@Workspace/auth';
import { eUserRole } from '@Workspace/_generated/enums';
import { IUserPropertiesDto } from '@Workspace/_generated/interfaces';
import { MenuItem } from 'primeng/api/menuitem';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'navigation-component',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavigationComponent implements OnInit {
    menuGroups: IMenuItem[][] = [];
    userProperties: IUserPropertiesDto;
    routeUrl: string = '';
    isMenuExpanded = false;

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
        this.userProperties = this.authService.userSettings;
    }

    ngOnInit(): void {
        this.setMenuItems();

        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                this.routeUrl = event.url;
            });
    }

    setMenuItems() {
        let g1 = [
            {
                label: 'Dashboard',
                icon: 'bi-house',
                url: '/dashboard',
                isVisible: () => true,
            } as IMenuItem,
            {
                label: 'Help',
                icon: 'bi-question-circle',
                url: '/help',
                userRoles: [eUserRole.SystemAdministrator, eUserRole.Client],
                isVisible: () => true,
            } as IMenuItem,
        ];

        let g2 = [
            {
                label: 'Users',
                icon: 'bi-people',
                url: '/users',
                userRoles: [eUserRole.SystemAdministrator, eUserRole.Manager],
                isVisible: () => true,
            } as IMenuItem,
            {
                label: 'Payments',
                icon: 'bi-cash-stack',
                url: '/payments',
                userRoles: [eUserRole.SystemAdministrator, eUserRole.Manager],
                isVisible: () => true,
            } as IMenuItem,
            {
                label: 'Audit',
                icon: 'bi-clock-history',
                url: '/audit-history',
                userRoles: [eUserRole.SystemAdministrator],
                isVisible: () => true,
            } as IMenuItem,
        ];

        let g3 = [
            {
                label: 'Profile',
                icon: 'bi-person',
                url: '/profile',
                isVisible: () => true,
            } as IMenuItem,
            {
                label: 'Log Out',
                icon: 'bi-power',
                command: () => {
                    this.logoutAsync();
                },
                isVisible: () => true,
            } as IMenuItem,
        ];
        
        this.menuGroups = [
            g1.filter(
                (x) =>
                    !x.userRoles || x.userRoles.indexOf(this.userProperties.role) != -1
            ),
            g2.filter(
                (x) =>
                    !x.userRoles || x.userRoles.indexOf(this.userProperties.role) != -1
            ),
            g3,
        ];
    }

    async logoutAsync() {
        await this.authService.logOut();
        this.router.navigate(['/auth/sign-in']);
    }

    toggleMenu() {
        document.body.classList.toggle('is-menu-expanded');
    }
}

export interface IMenuItem extends MenuItem {
    userRoles?: eUserRole[];
    isVisible: () => boolean;
    dynamicUrl: () => string;
}
