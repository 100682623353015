import { eUserRole } from '@Workspace/_generated/enums';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInGuard } from '@Workspace/guards';

const ROUTES: Routes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        canLoad: [SignInGuard],
        loadChildren: 
            () => import('apps/core/src/app/modules/dashboard/dashboard.module')
                .then(m => m.DashboardModule)

    },
    {
        path: 'help',
        canLoad: [SignInGuard],
        data: { roles: [eUserRole.SystemAdministrator, eUserRole.Client] },
        loadChildren: 
            () => import('apps/core/src/app/modules/help/help.module')
                .then(m => m.HelpModule)

    },
    {
        path: 'users',
        canLoad: [SignInGuard],
        data: { roles: [eUserRole.SystemAdministrator, eUserRole.Manager] },
        loadChildren: 
            () => import('apps/core/src/app/modules/users/users.module')
                .then(m => m.UsersModule)

    },
    {
        path: 'payments',
        canLoad: [SignInGuard],
        data: { roles: [eUserRole.SystemAdministrator, eUserRole.Manager] },
        loadChildren: 
            () => import('apps/core/src/app/modules/payments/payments.module')
                .then(m => m.PaymentsModule)

    },
    {
        path: 'audit-history',
        canLoad: [SignInGuard],
        data: { roles: [eUserRole.SystemAdministrator] },
        loadChildren: 
            () => import('apps/core/src/app/modules/audit/audit.module')
                .then(m => m.AuditModule)

    },
    {
        path: 'profile',
        canLoad: [SignInGuard],
        loadChildren: 
            () => import('apps/core/src/app/modules/profile/profile.module')
                .then(m => m.ProfileModule)

    },
    {
        path: 'auth',
        loadChildren: () =>
            import('apps/core/src/app/modules/auth/auth.module').then(
                (m) => m.AuthModule
            ),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(ROUTES, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
