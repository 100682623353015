import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface TabEvent {
    index: string;
    isNext: boolean;
}

interface InfoTabIndex {
    index: number;
    isFMC: boolean;
}

@Injectable({
    providedIn: 'root', // It will inject this provider at the root level of the application so it can be accessed anywhere.
})
export class FormEventService implements OnDestroy {
    constructor() {}

    private focusIndex: Subject<TabEvent> = new Subject<TabEvent>();
    private resetEvent: Subject<boolean> = new Subject<boolean>();
    private setTabFocusEvent: Subject<number> = new Subject<number>();

    private focusOut: Subject<boolean> = new Subject<boolean>();
    private focusIn: Subject<boolean> = new Subject<boolean>();
    private allIndexes: number[] = [];
    private componentsIndexs: number[] = [];
    private infoIndexes: InfoTabIndex[] = [];

    setFocusOut(b: boolean): void {
        this.focusOut.next(b);
    }

    getFocusOut(): Observable<boolean> {
        return this.focusOut.asObservable();
    }

    setFocusIn(b: boolean): void {
        this.focusIn.next(b);
    }

    getFocusIn(): Observable<boolean> {
        return this.focusIn.asObservable();
    }

    getReset(): Observable<boolean> {
        return this.resetEvent.asObservable();
    }

    getTabFocus(): Observable<number> {
        return this.setTabFocusEvent.asObservable();
    }
    setReset(y: boolean): void {
        this.resetEvent.next(y);
    }

    setIndexNums(arr: number[]): void {
        arr = arr.sort((a, b) => {
            return a - b;
        });
        arr.forEach((x) => {
            if (this.allIndexes.length === 0) {
                this.allIndexes.push(x);
            } else if (x !== this.allIndexes[this.allIndexes.length - 1]) {
                this.allIndexes.push(x);
            }
        });
        arr.forEach((x) => {
            const isFMC = this.componentsIndexs.indexOf(x) > -1;
            this.infoIndexes.push({ index: x, isFMC });
        });
    }

    setIndex(ind: number): void {
        this.componentsIndexs.push(ind);
        this.componentsIndexs = this.componentsIndexs.sort((a, b) => {
            return a - b;
        });
    }

    isFMC(index: number): boolean {
        return this.infoIndexes.some(
            (x) => x.isFMC === true && x.index === index
        );
    }

    nextTab(val: string): void {
        let index = this.allIndexes.indexOf(parseInt(val));
        if (index < this.allIndexes.length - 1 && index >= 0) {
            index += 1;
            if (this.isFMC(this.allIndexes[index])) {
                this.setTabFocusEvent.next(this.allIndexes[index]);
                this.focusIndex.next({
                    index: this.allIndexes[index].toString(),
                    isNext: true,
                });
            } else {
                this.setTabFocusEvent.next(this.allIndexes[index]);
            }
        }
    }

    changeFocus(val: string): void {
        this.focusIndex.next({ index: val, isNext: false });
    }

    getFocusIndex(): Observable<TabEvent> {
        return this.focusIndex.asObservable();
    }

    ngOnDestroy() {
        this.focusIndex.complete();
        this.resetEvent.complete();
        this.setTabFocusEvent.complete();
    }
}
