import { QrCodeModule } from 'ng-qrcode';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.module';
import { AccountController } from '@Workspace/_generated/services';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import {ToastModule} from 'primeng/toast';
import {ButtonModule} from 'primeng/button'
import {InputTextModule} from 'primeng/inputtext'

const ANGULAR_MODULES = [FormsModule, ReactiveFormsModule, CommonModule];

const NODE_MODULES = [QrCodeModule];

const APP_MODULES = [AuthRoutingModule, ToastModule, ButtonModule,InputTextModule];

const COMPONENTS = [AuthLayoutComponent];

const PAGES = [ResetPasswordComponent, SignInComponent, ForgotPasswordComponent];

const DIRECTIVES = [];

const PIPES = [];

const CONTROLLERS = [AccountController];

@NgModule({
    declarations: [...COMPONENTS, ...PAGES, ...DIRECTIVES, ...PIPES],
    imports: [...ANGULAR_MODULES, ...NODE_MODULES, ...APP_MODULES],
    providers: [...CONTROLLERS],
    exports: [],
})
export class AuthModule {}
