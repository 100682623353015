//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { SettingsService } from '@Workspace/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPreSignInDetailsDto } from './interfaces';
import { ILoginModel } from './interfaces';
import { IRefreshTokenModel } from './interfaces';
import { IUserStatisticsDto } from './interfaces';
import { IUserPropertiesDto } from './interfaces';
import { ISampleResponseModel } from './interfaces';
import { IChangePasswordModel } from './interfaces';
import { IResetPasswordModel } from './interfaces';
import { IUserPasswordResetDto } from './interfaces';
import { IUserEmailModel } from './interfaces';
import { IApiSettings } from './interfaces';
import { IClientPreviewDto } from './interfaces';
import { IGraphDataDto } from './interfaces';
import { IEmailCreateDto } from './interfaces';
import { IEmailDto } from './interfaces';
import { ILookupDto } from './interfaces';
import { eUserRole } from './enums';
import { ITimeZoneDto } from './interfaces';
import { INetworkDto } from './interfaces';
import { IPagingResultGeneric } from './interfaces';
import { IPaymentDto } from './interfaces';
import { IIdModelDto } from './interfaces';
import { IUserDto } from './interfaces';
import { IProfileUserDto } from './interfaces';
import { IUpdate2FADto } from './interfaces';

@Injectable() export class AccountController
{
	public GetUserPreSignInDetails(model: ILoginModel) : Observable<IPreSignInDetailsDto>
	{
		const body = <any>model;
		return this.httpClient.post<IPreSignInDetailsDto>(
		this.settingsService.createApiUrl('api/Account/GetUserPreSignInDetails'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: false
		})
		.pipe(map(response => response.body));
		
	}
	public SignIn(model: ILoginModel) : Observable<any>
	{
		const body = <any>model;
		return this.httpClient.post<any>(
		this.settingsService.createApiUrl('api/Account/SignIn'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: false
		})
		.pipe(map(response => response.body));
		
	}
	public RefreshToken(refreshTokenModel: IRefreshTokenModel) : Observable<any>
	{
		const body = <any>refreshTokenModel;
		return this.httpClient.post<any>(
		this.settingsService.createApiUrl('api/Account/RefreshToken'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: false
		})
		.pipe(map(response => response.body));
		
	}
	public GetCurrentUserStatistics() : Observable<IUserStatisticsDto>
	{
		return this.httpClient.get<IUserStatisticsDto>(
		this.settingsService.createApiUrl('api/Account/GetCurrentUserStatistics'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetCurrentUserProperties() : Observable<IUserPropertiesDto>
	{
		return this.httpClient.get<IUserPropertiesDto>(
		this.settingsService.createApiUrl('api/Account/GetCurrentUserProperties'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public ChangePassword(model: IChangePasswordModel) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/Account/ChangePassword'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public ResetPassword(model: IResetPasswordModel) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/Account/ResetPassword'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: false
		})
		.pipe(map(response => response.body));
		
	}
	public RequestResetPassword(model: IUserEmailModel) : Observable<IUserPasswordResetDto>
	{
		const body = <any>model;
		return this.httpClient.post<IUserPasswordResetDto>(
		this.settingsService.createApiUrl('api/Account/RequestResetPassword'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: false
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class ApplicationController
{
	public GetSettings() : Observable<IApiSettings>
	{
		return this.httpClient.get<IApiSettings>(
		this.settingsService.createApiUrl('api/Application/GetSettings'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: false
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class ClientController
{
	public GetClientData(userId: any) : Observable<IClientPreviewDto[]>
	{
		const body = <any>{ 'userId': userId };
		return this.httpClient.get<IClientPreviewDto[]>(
		this.settingsService.createApiUrl('api/Client/GetClientData'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetIndexGraphData() : Observable<IGraphDataDto>
	{
		return this.httpClient.get<IGraphDataDto>(
		this.settingsService.createApiUrl('api/Client/GetIndexGraphData'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class EmailController
{
	public CreateEmail(emailMessage: IEmailCreateDto) : Observable<ISampleResponseModel>
	{
		const body = <any>emailMessage;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/Email/CreateEmail'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public EmailServiceTrigger() : Observable<ISampleResponseModel>
	{
		return this.httpClient.get<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/Email/EmailServiceTrigger'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetEmails() : Observable<IEmailDto[]>
	{
		return this.httpClient.get<IEmailDto[]>(
		this.settingsService.createApiUrl('api/Email/GetEmails'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class ErrorController
{
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class LookupController
{
	public GetUsersByRole(userRole: eUserRole) : Observable<ILookupDto<any>[]>
	{
		const body = <any>{ 'userRole': userRole };
		return this.httpClient.get<ILookupDto<any>[]>(
		this.settingsService.createApiUrl('api/Lookup/GetUsersByRole'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetTimeZones() : Observable<ITimeZoneDto[]>
	{
		return this.httpClient.get<ITimeZoneDto[]>(
		this.settingsService.createApiUrl('api/Lookup/GetTimeZones'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class NetworkController
{
	public CreateNetwork(network: INetworkDto) : Observable<ISampleResponseModel>
	{
		const body = <any>network;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/Network/CreateNetwork'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetNetworks(queryOptions: any) : Observable<IPagingResultGeneric<INetworkDto>>
	{
		return this.httpClient.get<IPagingResultGeneric<INetworkDto>>(
		this.settingsService.createApiUrl('api/Network/GetNetworks') + queryOptions,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class PaymentController
{
	public GetPayments(queryOptions: any) : Observable<IPagingResultGeneric<IPaymentDto>>
	{
		return this.httpClient.get<IPagingResultGeneric<IPaymentDto>>(
		this.settingsService.createApiUrl('api/Payment/GetPayments') + queryOptions,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetPayment(paymentId: any) : Observable<IPaymentDto>
	{
		const body = <any>{ 'paymentId': paymentId };
		return this.httpClient.get<IPaymentDto>(
		this.settingsService.createApiUrl('api/Payment/GetPayment'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public UpdatePayment(payment: IPaymentDto) : Observable<ISampleResponseModel>
	{
		const body = <any>payment;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/Payment/UpdatePayment'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public DeletePayment(model: IIdModelDto) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/Payment/DeletePayment'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class TestController
{
	public GetPrivateData() : Observable<string>
	{
		return this.httpClient.get<string>(
		this.settingsService.createApiUrl('api/Test/GetPrivateData'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
@Injectable() export class UserController
{
	public GetUsers(queryOptions: any) : Observable<IPagingResultGeneric<IUserDto>>
	{
		return this.httpClient.get<IPagingResultGeneric<IUserDto>>(
		this.settingsService.createApiUrl('api/User/GetUsers') + queryOptions,
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetUser(userId: any) : Observable<IUserDto>
	{
		const body = <any>{ 'userId': userId };
		return this.httpClient.get<IUserDto>(
		this.settingsService.createApiUrl('api/User/GetUser'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true,
			params: new HttpParams({ fromObject: body })
		})
		.pipe(map(response => response.body));
		
	}
	public GetProfile() : Observable<IProfileUserDto>
	{
		return this.httpClient.get<IProfileUserDto>(
		this.settingsService.createApiUrl('api/User/GetProfile'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public UpdateProfile(model: IProfileUserDto) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/User/UpdateProfile'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public GetProfile2FA() : Observable<IPreSignInDetailsDto>
	{
		return this.httpClient.get<IPreSignInDetailsDto>(
		this.settingsService.createApiUrl('api/User/GetProfile2FA'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public Update2FA(model: IUpdate2FADto) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/User/Update2FA'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public Disable2FA() : Observable<ISampleResponseModel>
	{
		return this.httpClient.get<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/User/Disable2FA'),
		{
			responseType: 'json',
			observe: 'response',
			withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public UpdateUser(model: IUserDto) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/User/UpdateUser'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public ChangeActiveStatus(model: IIdModelDto) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/User/ChangeActiveStatus'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public ChangeLockStatus(model: IIdModelDto) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/User/ChangeLockStatus'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	public DeleteUser(model: IIdModelDto) : Observable<ISampleResponseModel>
	{
		const body = <any>model;
		return this.httpClient.post<ISampleResponseModel>(
		this.settingsService.createApiUrl('api/User/DeleteUser'),
		body,
		{
		responseType: 'json',
		observe: 'response',
		withCredentials: true
		})
		.pipe(map(response => response.body));
		
	}
	constructor (protected httpClient: HttpClient, protected settingsService: SettingsService) { } 
}
