import { eUserRole } from '@Workspace/_generated/enums';
import { IEnumDataAttribute } from '@Workspace/_generated/interfaces';

export enum eTooltipSize {
    Regular = 1,
    Small = 2,
}
export enum eTooltipFontSize {
    Regular = 1,
    Small = 2,
}

export enum eSortDir {
    Not_Set = 0,
    ASC = 1,
    DESC = 2,
}

// Please extend this enum for any new grid elements added
export enum eGridElements {
    Text = 1,
    Icon = 2,
    Label = 3,
    Checkbox = 4,
    Switch = 5,
    Thumbnail = 6,
    Date = 7,
    LastChange = 8,
    Hyperlink = 9,
    Enum = 10,
    EntityStatus = 11,
    Combination = 12,
    InstedObject = 13,
    Action = 100,
}

export enum eAnnotationAction {
    NotSet = 0,
    FreeHand = 1,
    Rectangle = 2,
    Circle = 3,
}

export enum eActionButton {
    Add_New = 1,
    Add_Role = 2,
    Edit = 3,
    View = 4,
    Activate = 5,
    Revoke_All_Access = 6,
    Deactivate = 7,
    Restore_Defaults = 8,
    Preview = 9,
    Print = 10,
    Download = 11,
    Copy_Link = 12,
    Copy_Password = 13,
    Dispose = 14,
    CSV = 15,
    JSON = 16,
    Remove = 17,
    Cancel = 18,
    Approve = 19,
    Export_As = 20,
    Send_Message = 21,
    Send_External_Emails = 22,
    Change_Status_Stage = 23,
    Reassign_Submission_Owner = 24,
    Submissions_List_CSV = 25,
    Archive = 26,
    Grid_Settings = 27,
    Show_My_Submissions = 28,
    Delete = 29,
}

export enum eLabelSize {
    Regular = 1,
    Big = 2,
}
export const eActionButtonName = new Map<number, any>([
    [eActionButton.Add_New, { icon: 'fas fa-plus-hexagon fa-fw' }],
    [eActionButton.Add_Role, { icon: 'fal fa-plus-hexagon fa-fw' }],
    [
        eActionButton.Activate,
        {
            icon: 'fal fa-toggle-on fa-fw',
            nameResourceKey: 'Activated',
            descriptionResourceKey:
                'Are you sure you want to activate this item?',
        },
    ],
    [eActionButton.Edit, { icon: 'fal fa-edit fa-fw' }],
    [eActionButton.Revoke_All_Access, { icon: 'fab fa-rev fa-fw' }],
    [
        eActionButton.Deactivate,
        {
            icon: 'fal fa-toggle-off fa-fw',
            nameResourceKey: 'Deactivate',
            descriptionResourceKey:
                'Are you sure you want to deactivate this item?',
        },
    ],
    [
        eActionButton.Delete,
        {
            icon: 'fal fa-trash fa-fw',
            nameResourceKey: 'Delete',
            descriptionResourceKey:
                'Are you sure you want to delete this item?',
        },
    ],
    [eActionButton.View, { icon: 'far fa-eye fa-fw' }],
    [eActionButton.Restore_Defaults, { icon: 'fal fa-undo fa-fw' }],
    [eActionButton.Preview, { icon: 'fal fa-file-alt fa-fw' }],
    [eActionButton.Print, { icon: 'fal fa-print fa-fw' }],
    [eActionButton.Download, { icon: 'fal fa-file-download fa-fw' }],
    [eActionButton.Copy_Link, { icon: 'far fa-copy fa-fw' }],
    [eActionButton.Copy_Password, { icon: 'fas fa-key fa-fw' }],
    [eActionButton.Dispose, { icon: 'fal fa-trash fa-fw' }],

    [
        eActionButton.CSV,
        { icon: 'fal fa-file-csv fa-fw ', parent: eActionButton.Export_As },
    ],
    [
        eActionButton.JSON,
        { icon: 'fal fa-file-alt fa-fw', parent: eActionButton.Export_As },
    ],
    [eActionButton.Remove, { icon: 'fal fa-trash fa-fw' }],
    [
        eActionButton.Cancel,
        {
            icon: 'fal fa-times-circle fa-fw',
            nameResourceKey: 'Cancel',
            descriptionResourceKey:
                'Are you sure you want to cancel this item?',
        },
    ],
    [
        eActionButton.Approve,
        {
            icon: 'fal fa-check-double fa-fw',
            nameResourceKey: 'Approve',
            descriptionResourceKey:
                'Are you sure you want to approve this item?',
        },
    ],
    [eActionButton.Export_As, { icon: 'fal fa-file-download fa-fw' }],
]);

export enum eDialogMode {
    Not_Set = 0,
    Default = 1,
    Confirm = 2,
    Delete = 3,
}

export const eUserRoleName = new Map<number, IEnumDataAttribute>([
    [eUserRole.NotSet, {}],
    [eUserRole.SystemAdministrator, { name: 'System Admin' }],
    [eUserRole.Manager, { name: 'Manager' }],
    [eUserRole.Client, { name: 'Client' }],
    [eUserRole.NoAccessible, {}],
]);

export enum eMessageSeverity {
    Error = "Error",
    Success = "Success",
    Warning = "Warning",
}
