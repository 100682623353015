<div class="login">
    <div class="login__image"></div>
    <div class="login__aside">
        <div class="login__inner">
            <img *ngIf="!isSignInPage" class="login__logo login__logo--top logo-light" src="assets/BullBlock_logotyp.svg" alt="Bull Block">

            <ng-container *ngTemplateOutlet="content"></ng-container>
            
            <div class="login__meta">
                For optimal system performance the recommended browsers are Google Chrome, Microsoft Edge and Safari.
            </div>
        </div>
    </div>
</div>
