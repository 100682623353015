import { Component, Input, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'auth-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AuthLayoutComponent implements OnInit {

    @Input() content: TemplateRef<any>;
    @Input() isSignInPage: boolean;

    constructor() {}

    ngOnInit() {}
}
