import { HttpClient, HttpErrorResponse, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService, StorageService } from '@Workspace/services';
import { eSocialProvider } from 'libs/_generated/enums';
import { ILoginModel, IRefreshTokenModel, IUserPropertiesDto } from 'libs/_generated/interfaces';
import * as moment from 'moment-timezone';
import { Header } from 'primeng/api';
import { BehaviorSubject, from, Subject, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';

import { IAuthService } from './iauth.service';

@Injectable()
export class AuthTokenService implements IAuthService {

    private static USER_ACCES_TOKEN_KEY: string = 'accessToken';
    private static USER_ACCES_TOKEN_EXPIRE_KEY: string = 'expires';
    private static USER_REFRESH_TOKEN_KEY: string = 'refreshToken';
    private static USER_REFRESH_TOKEN_EXPIRE_KEY: string = 'refreshTokenExpires';
    private static USER_SETTINGS: string = 'user_Settings';

    private static LOGIN_PATH: string = 'api/Account/SignIn';
    private static REFRESH_LOGIN_PATH: string = 'api/Account/RefreshToken';
    private static USER_PROPERTIES_PATH: string = 'api/Account/GetCurrentUserProperties';

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private settingService: SettingsService
    ) {
        this.headers.append('Accept', 'application/json');
    }

    private headers: HttpHeaders = new HttpHeaders();
    private _userAccessToken: string;
    private _userProperties: IUserPropertiesDto = {} as IUserPropertiesDto;
    private _isLogged = false;
    private _$userStateChange: BehaviorSubject<boolean> = new BehaviorSubject<
        boolean
    >(false);
    public async GetUserAccessTokenAsync(url?:string) {
        var accessTokenExpiration = await this.storageService.getExpiration(AuthTokenService.USER_ACCES_TOKEN_KEY);
			if (!!accessTokenExpiration) {
				accessTokenExpiration.setMinutes(accessTokenExpiration.getMinutes() - 5);
				if (accessTokenExpiration.getTime() < new Date().getTime()) {
                    this.refreshToken();
				}

            return this._userAccessToken;
        } else {
            await this.refreshToken();

            return this._userAccessToken;
        }
	}

    public get $userState() {
        return this._$userStateChange.asObservable();
	}

    public get userSettings() {
        return this._userProperties;
	}

    public get isLogged() {
        return this._isLogged;
	}

    public async reloadUserdataAsync() {
        await this.refreshToken();
	}

    public async reloadUserSettingsAsync() {
        await this.postSaveUserProperties(this._userAccessToken);
    }

    async login(username: string, password: string, secret: string, code: string) {
        await this.loginApi(username, password, secret, code);
	}

    async logOut() {
        await this.storageService.clear();
        await this.reloadLogin();
	}

    async initializeAsync() {
        await this.reloadLogin();
	}

    async loginSocial(socialProvider: eSocialProvider) {
        // await this.loginSocialProvider(socialProvider);
    }

    erroHandler(error: HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
                window.location.href = '#/sign-in';
            }
        }
        return throwError(error.message || 'server Error');
    }

    authorizeRequest(request: HttpRequest<any>, next: HttpHandler) {
        return from(this.GetUserAccessTokenAsync()).pipe(
            concatMap(apiToken => {
                if (!request.withCredentials || !apiToken) {
                    return next
                        .handle(request)
                        .pipe(catchError(this.erroHandler));
                }

                const newRequest = request.clone({
                    setHeaders: { Authorization: `Bearer ${apiToken}` }
                });

                return next.handle(newRequest);
            })
        );
    }

    private async loginApi(email: string, password: string, secret: string, code: string) {
        var formData = await this.createFormData(email, password, secret, code);
        var accessToken = await this.postSaveLogin(AuthTokenService.LOGIN_PATH, formData);
        await this.postSaveUserProperties(accessToken);
        await this.reloadLogin();
    }

	private createFormData(email: string, password: string, secret: string, code: string) {
        return <any>{
            email: email,
            password: password,
            secret2FA: secret,
            code2FA: code
        };
	}

    private async postSaveLogin(url : string, formData: URLSearchParams, isReferesh: boolean = false): Promise<string> {
        try {
            var response = await this.http.post(this.settingService.createApiUrl(url), formData, {
				headers: this.headers
			})
            .toPromise();

            var data = response || {};

            return await this.saveAndGetAccessToken(data);
        }
        catch (error) {
				if (isReferesh) {
					this.logOut();
                throw error;
				}
				switch (error.status) {
					case 400:
                    throw error;
					default:
                    throw error;
				}
	}
    }

    private async saveAndGetAccessToken(data) {
        if (!data) {
            return undefined;
        }

        const expire: Date = new Date(data[AuthTokenService.USER_ACCES_TOKEN_EXPIRE_KEY]);
        const expireRefresh: Date = new Date(data[AuthTokenService.USER_REFRESH_TOKEN_EXPIRE_KEY]);

        await this.storageService.set(AuthTokenService.USER_ACCES_TOKEN_KEY, data[AuthTokenService.USER_ACCES_TOKEN_KEY], expire);
        await this.storageService.set(AuthTokenService.USER_REFRESH_TOKEN_KEY, data[AuthTokenService.USER_REFRESH_TOKEN_KEY], expireRefresh);

        return data[AuthTokenService.USER_ACCES_TOKEN_KEY];
    }

    private async postSaveUserProperties(token: string) {
		var headers = new HttpHeaders();
		headers = headers.append("Authorization", "Bearer " + token);

        var data = await new Promise((resolve, reject) => {
            this.http
			.get(this.settingService.createApiUrl(AuthTokenService.USER_PROPERTIES_PATH), {
				headers: headers
			})
                .subscribe((response) => {
                    resolve(response || {});
				});
			});

        var expiration = await this.storageService.getExpiration(AuthTokenService.USER_ACCES_TOKEN_KEY);
        await this.storageService.set(AuthTokenService.USER_SETTINGS, data, expiration)
	}

    private async reloadLogin() {
        var accessToken = await this.storageService.get(AuthTokenService.USER_ACCES_TOKEN_KEY);
        this._isLogged = !!await this.storageService.get(AuthTokenService.USER_REFRESH_TOKEN_KEY);

        var userProperties: IUserPropertiesDto =  (await this.storageService.get(AuthTokenService.USER_SETTINGS)) || {} as IUserPropertiesDto;

        this._userAccessToken = accessToken;
        this._userProperties = userProperties;
        this._$userStateChange.next(this._isLogged);
    }

    private _isRunningRefreshToken: boolean = false;
    private _$refreshTokenProgress = new Subject<void>();

    private async refreshToken() {
        return new Promise((resolve) => {
            let subscribtion = this._$refreshTokenProgress
                .subscribe(() => {
                    resolve(null);
                    subscribtion.unsubscribe();
                    this._isRunningRefreshToken = false;
                });

            if (!this._isRunningRefreshToken) {
                this._isRunningRefreshToken = true;
                this.refreshTokenAsync().then(() => {
                    this._$refreshTokenProgress.next();
                });
            }
        });
    }

    private async refreshTokenAsync() {

        var refreshToken = await this.storageService.get(AuthTokenService.USER_REFRESH_TOKEN_KEY);
					if (!refreshToken) {
            await this.reloadLogin();
            return;
					}

					const urlSearchParams = new URLSearchParams();
					urlSearchParams.append("refreshToken", refreshToken);

        var token = await this.postSaveLogin(AuthTokenService.REFRESH_LOGIN_PATH, urlSearchParams, true);
        await this.postSaveUserProperties(token);
        await this.reloadLogin();
		}
	}
