import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from '@Workspace/auth';
import { Constants } from '@Workspace/common';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'Workspace-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

    isLogged = false;
    previousUrl = '';

    constructor(
        public authService: AuthService,
        private router: Router
    ) {}

    ngOnInit() {
        this.isLogged = this.authService.isLogged;

        this.authService.$userState.subscribe(
            isLogged => {
                if (!isLogged) {
                    document.body.classList.remove(Constants.DARK_THEME_CLASS);
                }
                else {
                    !!this.authService.userSettings.isDarkTheme ? 
                        document.body.classList.add(Constants.DARK_THEME_CLASS):
                        document.body.classList.remove(Constants.DARK_THEME_CLASS);
                }
            }
        )

        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                this.addModuleSlugToBody(event.url);
            });

        this.addModuleSlugToBody(this.router.url);
    }

    addModuleSlugToBody(url: string) {
        if (this.previousUrl) {
            document.body.classList.remove(this.previousUrl);
        }

        let currentUrlSlug = url.split('/')[1];

        if (currentUrlSlug) {
            document.body.classList.add(currentUrlSlug);
        }

        this.previousUrl = currentUrlSlug;
    }
}
