<p-toast></p-toast>


<div fullPageLoader="isLoading"
    class="main-container d-flex"
    [ngClass]="{ 'is-logged': authService.isLogged }"
>
    <div class="page-loader">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>

    <navigation-component *ngIf="authService.isLogged"></navigation-component>
    <div class="main-content flex-fill">
        <div class="main-content__container flex-fill">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="main-container__footer">
        <footer-component></footer-component>
    </div>
</div>