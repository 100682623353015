import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as directives from './index';

const DIRECTIVES = [
    directives.FullPageLoaderDirective,
    directives.HighlightDirective,
    directives.DropZoneDirective
];

@NgModule({
    declarations: [...DIRECTIVES],
    imports: [CommonModule],
    exports: [...DIRECTIVES],
})
export class DirectivesModule {}
