<auth-layout [content]="content" [isSignInPage]="false"></auth-layout>

<ng-template #content>
    <div class="login__container">
        <h2>Forgot Password</h2>
        <div class="login__description">Please enter your registered email address. Instructions will be sent to reset your password.</div>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="p-fluid">
                <div class="p-field">
                    <span class="p-float-label">
                        <input id="email" type="email" [email]="true" formControlName="email" 
                        [ngClass]="{'ng-dirty': submitted && f.email.errors}" pInputText 
                        [tooltipDisabled]="!(f.email.invalid && (f.email.dirty || f.email.touched))" [pTooltip]="getEmailErrors(f.email.errors)" tooltipPosition="top"/>
                        <label for="float-input">Email</label>
                    </span>
                </div>
            </div>
            <button pButton [disabled]="loading" type="submit" label="Send Email">
            </button>
        </form>
        <div class="login__email-sent" [ngClass]="{'login__email-sent--success': isRequestResetPasswordSuccess}">
            <i class="bi bi-envelope-check"></i>
            <span>
                Email sent succesfully, please check your email for further instructions.
            </span>
        </div>
    </div>
</ng-template>