import { AuthModule } from './modules/auth/auth.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { storeFreeze } from 'ngrx-store-freeze';
import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';

//PIRMENG
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

//SERVICES
import { AuthService } from '@Workspace/auth';
import * as services from '@Workspace/services';
import { AuthenticationHttpInterceptor } from 'libs/interceptors/authentication-http.interceptor';
import { AuthTokenService } from 'libs/services/auth/auth.token.service';
import { DirectivesModule } from 'libs/directives/directives.module';
import { SignInGuard } from '@Workspace/guards';

//APP_MODULES
import { AppRoutingModule } from './app-routing.module';

//COMPONENTS
import { AppComponent } from './modules/app.component';
import { FormEventService, PageLoaderService, SettingsService, StorageService } from '@Workspace/services';

import { environment } from '../environments/environment';
import { AppInitializer } from './app.initializer';
import { NotificationService } from '@Workspace/services';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FooterComponent } from './components/footer/footer.component';

//NODE_MODULES
// import { AuthAdSevice } from 'libs/services/auth/auth.ad.service';
import { MsalWrapperService } from 'libs/services/auth/msad/msal-wrapper.service';
import { FullPageLoaderDirective } from '@Workspace/directives';
import { TooltipModule } from 'primeng/tooltip';

PlotlyModule.plotlyjs = PlotlyJS;

const ANGULAR_MODULES = [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule
];

const NODE_MODULES = [ToastModule];

const APP_MODULES = [
    AppRoutingModule,
    PlotlyModule,
    AuthModule,
    DirectivesModule,
    TooltipModule
];

const PAGES = [];

const COMPONENTS = [
    AppComponent,
    NavigationComponent,
    FooterComponent
];

const DIRECTIVES = [];

const PIPES = [];

const CONTROLLERS = [];

const SERVICES = [
    AppInitializer,
    SettingsService,
    StorageService,
    PageLoaderService,
    AuthTokenService,
    MsalWrapperService,
    AuthService,
    ConfirmationService,
    NotificationService,
    MessageService,
    SignInGuard
];

@NgModule({
    declarations: [
        ...PAGES,
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES,
        NavigationComponent,
        FooterComponent
    ],
    imports: [
        ...ANGULAR_MODULES,
        ...NODE_MODULES,
        ...APP_MODULES,
        StoreModule.forRoot(
            {},
            { metaReducers: !environment.production ? [storeFreeze] : [] }
        ),
        EffectsModule.forRoot([]),
        TranslateModule.forRoot(),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        StoreRouterConnectingModule.forRoot()

    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitializer: AppInitializer) => {
                return () => appInitializer.initializeAsync();
            },
            deps: [AppInitializer],
            multi: true,
        },
        ...SERVICES,
        ...CONTROLLERS,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationHttpInterceptor,
            multi: true
        },
        FormEventService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
