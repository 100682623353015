import { Injectable } from '@angular/core';
import { SettingsService } from '@Workspace/services';
import { AuthService } from '@Workspace/auth';
import { MsalWrapperService } from 'libs/services/auth/msad/msal-wrapper.service';

@Injectable()
export class AppInitializer {
    constructor(private settingsService: SettingsService, private authService: AuthService, private msalWrapperService: MsalWrapperService) {}

    async initializeAsync() {
        await this.settingsService.initializeAsync();
        const isAd = await this.authService.getIsAd();

        if (isAd) {
            await this.msalWrapperService.initializeAsync();
            await this.msalWrapperService.msalService.handleRedirectPromise();
            await this.authService.initializeAsync();
        }
        else{
            await this.authService.initializeAsync();
        }
    }
}