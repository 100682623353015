import { ToastService } from './../../../../../../../../libs/services/toast/toast.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatchingValidator } from '@Workspace/common';
import { IResetPasswordModel } from '@Workspace/_generated/interfaces';
import { AccountController } from '@Workspace/_generated/services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    form: FormGroup;
    submitted = false;
    loading = false;
    isResetPasswordSuccess = false;
    token: string;
    userId: string;
    ngInitUnsubscribe: Subject<any> = new Subject<any>();

    constructor(
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private accountController: AccountController,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.form = this.formBuilder.group(
            {
                password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
                confirmPassword: ['', Validators.required],
            },
            {
                validator: MatchingValidator('password', 'confirmPassword')
            }
        );

        this.token = this.route.snapshot.queryParams['token'];
        this.userId = this.route.snapshot.queryParams['userid'];
    }

    get f() {
        return this.form.controls;
    }

    onSubmit() {
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }

        this.loading = true;

        const model = {
            newPassword: this.f.password.value,
            confirmPassword: this.f.confirmPassword.value,
            token: this.token,
            userId: this.userId
        } as IResetPasswordModel;

        this.accountController.ResetPassword(model)
        .pipe(
            takeUntil(this.ngInitUnsubscribe),
            // finally()
        ).subscribe({
            next: res => {
                this.isResetPasswordSuccess = true;
            },
            error: err => {
                this.toastService.addHttpError(err)
                this.loading = false;
            }
        })
    }

    getPasswordErrors(errors: any) {
        if (errors?.required) return 'Password is required';
        if (errors?.pattern) return 'The password should contain at least 8 characters containing an upper case character, a special character and a number e.g.: - “pyertAcdj@1374”.';
        return '';
    }

    getConfirmPasswordErrors(errors: any) {
        if (errors?.required) return 'Confirm Password is required';
        if (errors?.mustMatch) return 'Password and Confirm Password must match';
        return '';
    }
}
