import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Constants } from './constants';

export function MatchingValidator(
    controlName: string,
    matchingControlName: string
) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    };
}

export function greaterThanValidator(
    controlName: string,
    controlUnitName: string,
    minControlName: string,
    minControlUnitName: string
) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const controlUnit = formGroup.controls[controlUnitName];
        const minControl = formGroup.controls[minControlName];
        const minControlUnit = formGroup.controls[minControlUnitName];

        if (control.errors && !control.errors.lessOrEqual) {
            return;
        }
        if (
            +control.value <= +minControl.value &&
            +controlUnit.value <= +minControlUnit.value
        ) {
            control.setErrors({ lessOrEqual: true });
        } else {
            control.setErrors(null);
        }
    };
}

// export function notMatchingValidator(
//     pattern: RegExp = Constants.specialCharactersRegex,
//     message: string = 'Cannot contain any of these characters: \\ / * [ ] : ?'
// ) {
//     return (formControl: FormControl): ValidationErrors => {
//         return (formControl.value as string).match(pattern)
//             ? {
//                   [message]: true,
//               }
//             : null;
//     };
// }