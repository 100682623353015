//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { IEnumDataAttribute } from './interfaces';

export enum eEnvironment { 
	NotSet = 0, 
	Local = 1, 
	Development = 2, 
	QA = 3, 
	Staging = 4, 
	Live = 5, 
	Migration = 6
}

export const eEnvironmentName = new Map<number, IEnumDataAttribute>([
[eEnvironment.NotSet, {}],
[eEnvironment.Local, {}],
[eEnvironment.Development, {}],
[eEnvironment.QA, {}],
[eEnvironment.Staging, {}],
[eEnvironment.Live, {}],
[eEnvironment.Migration, {}]]);

export enum eSocialProvider { 
	NotSet = 0, 
	AzureAD = 1, 
	Google = 2, 
	Facebook = 3, 
	Instagram = 4, 
	Twitter = 5
}

export const eSocialProviderName = new Map<number, IEnumDataAttribute>([
[eSocialProvider.NotSet, {}],
[eSocialProvider.AzureAD, {}],
[eSocialProvider.Google, {}],
[eSocialProvider.Facebook, {}],
[eSocialProvider.Instagram, {}],
[eSocialProvider.Twitter, {}]]);

export enum eFilterDefiniton { 
	Equals = 1, 
	Contains = 2
}

export const eFilterDefinitonName = new Map<number, IEnumDataAttribute>([
[eFilterDefiniton.Equals, {}],
[eFilterDefiniton.Contains, {}]]);

export enum eUserRole { 
	NotSet = 0, 
	SystemAdministrator = 1, 
	Client = 2, 
	Manager = 3, 
	NoAccessible = 99
}

export const eUserRoleName = new Map<number, IEnumDataAttribute>([
[eUserRole.NotSet, {}],
[eUserRole.SystemAdministrator, {name:"Administrator"}],
[eUserRole.Client, {name:"Client"}],
[eUserRole.Manager, {name:"Manager"}],
[eUserRole.NoAccessible, {name:"No Accessible"}]]);

export enum ePortfolioType { 
	NotSet = 0, 
	Custom = 1, 
	OneYear = 2
}

export const ePortfolioTypeName = new Map<number, IEnumDataAttribute>([
[ePortfolioType.NotSet, {name:""}],
[ePortfolioType.Custom, {name:"Custom"}],
[ePortfolioType.OneYear, {name:"One Year"}]]);

export enum eParentType { 
	NotSet = 0, 
	Portfolio = 1, 
	Index = 2
}

export const eParentTypeName = new Map<number, IEnumDataAttribute>([
[eParentType.NotSet, {}],
[eParentType.Portfolio, {}],
[eParentType.Index, {}]]);

export enum eEntityStatus { 
	NotSet = 0, 
	Active = 1, 
	Inactive = 2, 
	Deleted = 99
}

export const eEntityStatusName = new Map<number, IEnumDataAttribute>([
[eEntityStatus.NotSet, {}],
[eEntityStatus.Active, {}],
[eEntityStatus.Inactive, {}],
[eEntityStatus.Deleted, {}]]);

export enum eDaysOfWeek { 
	Sunday = 0, 
	Monday = 1, 
	Tuesday = 2, 
	Wednesday = 3, 
	Thursday = 4, 
	Friday = 5, 
	Saturday = 6
}

export const eDaysOfWeekName = new Map<number, IEnumDataAttribute>([
[eDaysOfWeek.Sunday, {name:"Sunday",shortName:"Sun"}],
[eDaysOfWeek.Monday, {name:"Monday",shortName:"Mon"}],
[eDaysOfWeek.Tuesday, {name:"Tuesday",shortName:"Tue"}],
[eDaysOfWeek.Wednesday, {name:"Wednesday",shortName:"Wed"}],
[eDaysOfWeek.Thursday, {name:"Thursday",shortName:"Thu"}],
[eDaysOfWeek.Friday, {name:"Friday",shortName:"Fri"}],
[eDaysOfWeek.Saturday, {name:"Saturday",shortName:"Sat"}]]);

