export class Constants {
    public static FireBaseApiKey: string = '';
    public static FireBaseAuthDomain: string = '';

    public static AD_IFRAME_REDIRECT_URL = '/assets/auth.html';
    public static CLIENT_SETTINGS_URL = '/assets/apiSettings.json';
    public static API_SETTINGS_URL = 'api/Application/GetSettings';
    public static USER_PROPERTIES_PATH: string = 'api/Account/GetCurrentUserProperties';
    public static IS_AD_KEY = "isAD";

    public static ROWS_PER_PAGE_OPTIONS = [25, 50, 100, 250, 500];
    public static ROWS_PER_PAGE_INITIAL_OPTION = 50;

    public static LANG_CODE: string = 'lang_code';
    public static DEFAULT_LANG_CODE: string = 'en';
    public static GuidEmpty: string = '00000000-0000-0000-0000-000000000000';

    static ACCESS_TOKEN_KEY = 'access_token';
    static SERVER_SETTINGS = 'server_settings';

    static LAST_URL_KEY = 'lastUrl';

    public static TOOLTIP_DELAY = { HIDE_DELAY: 10, SHOW_DELAY: 700 };

    public static naturalNumberRegx: RegExp =
        /^[1-9](?:\d{0,2}(?:,\d{3})+|\d*)$/;
    public static emailRegx: RegExp =
        /[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}/;
    public static passwordRegx: RegExp = 
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}/;
    public static phoneRegx2: RegExp =
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    public static richTextEmptySpaceRegex: RegExp =
        /<\/?(?!a)(?!img)\w*\b[^>]*>/gi;

    static GRID_DATE_FORMAT = 'dd/MM/yyyy';
    static GRID_LASTCHANGE_DATE_FORMAT = 'dd-MMM-yy';
    static GRID_DATETIME_FORMAT = 'dd-MMM-yyyy hh:mm a';
    static GRID_DATE_TOOLTIP_FORMAT = 'dd-MMM-yyyy hh:mm a';
    static DEFAULT_LABEL_DATETIME_FORMAT = 'MMM dd, yyyy hh:mm a';
    static DEFAULT_YEAR_RANGE = '1900:2030';
    static DEFAULT_LABEL_DATE_FORMAT = 'MMM dd, yyyy';
    static DEFAULT_TIME_FORMAT = 'hh:mm a';
    static DEFAULT_FORMAT_DATETIME = 'dd-MMM-yyyy';
    static DEFAULT_FORMAT_CALENDAR = 'dd/mm/yy';

    public static ALLOWED_FILE_TYPES = '.ppt,.xls,.docx,.jpg,.rar,.bmp,.otf,.pdf,.jpeg,.wmf,.ps,.gif,.zip,.TTF,.tif,.eps,.ai,.tiff,.psd,.pptx,.doc,.png,.odt';
    public static DARK_THEME_CLASS = "dark-theme";
}
