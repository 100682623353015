import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ModelError } from '../models/modelError';
import { Functions } from './functions';

/**
 * @abstract
 */
@Component({
    template: '',
})
export abstract class BaseErrorForm {
    constructor() {}

    @Input() messages: { [key: string]: string };
    @Input('key') public key: string;
    @Input() public fieldlabel: string;
    @Input() public showValidationOnSubmit: boolean;
    @Input('showValidation')
    public set showValidation(val: boolean) {
        this._showValidation = val;
        this.showValidationChange.emit(val);
    }
    public get showValidation() {
        return this._showValidation;
    }

    @Input() set control(c: FormControl) {
        this._control = c;
        if (this.controlSub) this.controlSub.unsubscribe();
    }
    get control() {
        return this._control;
    }

    @Input('errors')
    public set errors(errors: ModelError[]) {
        this._errors = errors;
        if (!!errors && Array.isArray(errors) && errors.length > 0) {
            if (
                !!this.key &&
                errors.some(
                    (x) => x.Key.toLowerCase() === this.key.toLowerCase()
                )
            ) {
                let error = errors.find(
                    (x) => x.Key.toLowerCase() === this.key.toLowerCase()
                );
                if (error.Messages.length > 0) this.isInvalid = true;
            }
        } else this.isInvalid = false;
    }
    public get errors() {
        return this._errors;
    }

    @Output() showValidationChange = new EventEmitter<boolean>();

    isDisabled: boolean;
    isInvalid: boolean;
    controlSub: Subscription;
    private _control;
    private _errors: any;
    private _showValidation: boolean = true;

    isFieldInvalid() {
        if (
            ((this.showValidationOnSubmit && this.showValidation) ||
                !this.showValidationOnSubmit) &&
            (this.isInvalid ||
                (this.control && Functions.isFieldInvalid(this.control)))
        )
            return true;
        return false;
    }
}
