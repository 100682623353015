import { ICustomSelectItem } from '@Workspace/interfaces';
import * as enums from '@Workspace/_generated/enums';
import { IEnumDataAttribute } from '@Workspace/_generated/interfaces';
import * as clientSideEnums from './enums';

export interface IEnumType {
    name: string;
    enumValues: Map<number, IEnumDataAttribute>;
}

export class LookUps {
    public static EnumType: IEnumType;

    public static getEnumByName(enumName: string): IEnumType {
        if (enums[enumName + 'Name'] !== undefined) {
            return { name: enumName, enumValues: enums[enumName + 'Name'] };
        } else if (enums[enumName] !== undefined) {
            let enumValues = this.getEnumDataAttributeListFromEnum(enumName);
            return { name: enumName, enumValues: enumValues };
        }
        return {
            name: enumName,
            enumValues: new Map<number, IEnumDataAttribute>(),
        };
    }

    public static getEnumValuesForDropdown(
        enumName,
        labelPropName: string = 'name',
        withoutNullAsOption: boolean = false,
        ...excludedValues: number[]
    ): ICustomSelectItem[] {
        let result: ICustomSelectItem[] = [];

        if (typeof enumName !== 'string') {
            enumName = this.getEnumName(enumName);
        }
        LookUps.EnumType = this.getEnumByName(enumName);
        try {
            LookUps.EnumType.enumValues.forEach(
                (item: IEnumDataAttribute, key) => {
                    if (!(excludedValues.indexOf(key) >= 0)) {
                        result.push({
                            value: key,
                            label: item[labelPropName],
                            icon: item.icon,
                            title: item.name,
                            // color:
                            //   !item.color || item.color.startsWith('#')
                            //     ? item.color
                            //     : '#' + item.color,
                            sortOrder: item.sortOrder,
                            styleClass: '',
                        } as ICustomSelectItem);
                    }
                }
            );
            if (!withoutNullAsOption) {
                let firstObj = {
                    value: null,
                    label: '-',
                    title: '-',
                    styleClass: null,
                    icon: '',
                } as ICustomSelectItem;
                result.unshift(firstObj);
            }

            result = result.map((x) => {
                if (x.color)
                    x.color = x.color.startsWith('#') ? x.color : '#' + x.color;
                return x;
            });
        } catch (error) {}

        return result;
    }

    public static getClientSideEnumsList(
        enumName: string,
        ...excludedValues
    ): ICustomSelectItem[] {
        var enumKeys = Object.keys(clientSideEnums[enumName]);

        var enumData = enumKeys.slice(enumKeys.length / 2).map((x) => {
            if (!excludedValues.includes(clientSideEnums.eActionButton[x])) {
                return {
                    value: clientSideEnums.eActionButton[x],
                    label: x,
                } as ICustomSelectItem;
            }
        });

        return enumData.filter((x) => x != undefined);
    }

    //Utility functions
    public static getEnumName(enumObj: any): string {
        for (let name in enums) {
            if (enums[name] === enumObj) {
                return name;
            }
        }

        return undefined;
    }

    public static getEnumDataAttributeListFromEnum(
        enumName
    ): Map<number, IEnumDataAttribute> {
        var result = new Map<number, IEnumDataAttribute>();
        for (var key in enums[enumName]) {
            if (!isNaN(Number(key))) {
                result.set(parseInt(key), {
                    name: enums[enumName][parseInt(key)],
                });
            }
        }
        return result;
    }

    public static getEnumDataAttributeFromEnum(
        enumName,
        key
    ): IEnumDataAttribute {
        var result = {} as IEnumDataAttribute;

        for (var enumKey in enums[enumName]) {
            if (!isNaN(Number(enumKey)) && key == +enumKey) {
                result = this.getEnumByName(enumName).enumValues.get(+key);
            }
        }
        return result;
    }

    public static getSocialProviderTypes(): ICustomSelectItem[] {
        return this.getEnumValuesForDropdown(
            'eSocialProvider',
            'name',
            true,
            enums.eSocialProvider.NotSet
        );
    }
}
