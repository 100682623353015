import { AuthenticationResult, AuthError, Configuration, EventMessage, EventType, PopupRequest, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { SettingsService } from '@Workspace/services';
import { BehaviorSubject, Observable } from 'rxjs';



export class MsalService extends PublicClientApplication {
    constructor(
        private settingsService: SettingsService
    ) {
        super(settingsService.AzureAdConfig);

        super.addEventCallback((message)=>{
            if(message){
                this._msalSubject.next(message);
            }
        });
    }

    private _msalSubject: BehaviorSubject<EventMessage> = new BehaviorSubject<EventMessage>({
        payload: {
            username: "--"}
    } as EventMessage);

    public get Status(){
        return this._msalSubject.asObservable();
    }


    private isUnprotectedResource(url: string): boolean {
        const configUnprotectedResources =
            this.settingsService.AzureAdAngularConfig.unprotectedResources ||
            [];

        return configUnprotectedResources.some(
            resource => url.indexOf(resource) > -1
        );
    }

    private isEmpty(str: string): boolean {
        return typeof str === 'undefined' || !str || 0 === str.length;
    }

    public loginPopup(request?: PopupRequest): Promise<any> {
        return super
            .loginPopup(request)
            .then((authResponse: AuthenticationResult) => {
                return authResponse;
            })
            .catch((error: AuthError) => {
                console.error('Error during login:\n' + error.errorMessage);
                throw error;
            });
    }

    public getScopesForEndpoint(endpoint: string): Array<string> {
        

        // if user specified list of unprotectedResources, no need to send token to these endpoints, return null.
        const isUnprotected = this.isUnprotectedResource(endpoint);
        if (isUnprotected) {
            return null;
        }

        const protectedResourceMap = new Map(
                      this.settingsService.AzureAdAngularConfig.protectedResourceMap
                  );

        // process all protected resources and send the matched one
        const keyForEndpoint = Array.from(protectedResourceMap.keys()).find(
            key => endpoint.indexOf(key) > -1
        );
        if (keyForEndpoint) {
            return protectedResourceMap.get(keyForEndpoint);
        }

        /*
         * default resource will be clientid if nothing specified
         * App will use idtoken for calls to itself
         * check if it's staring from http or https, needs to match with app host
         */
        if (
            endpoint.indexOf('http://') > -1 ||
            endpoint.indexOf('https://') > -1
        ) {

        } else {
            /*
             * in angular level, the url for $http interceptor call could be relative url,
             * if it's relative call, we'll treat it as app backend call.
             */
            return new Array<string>(
                this.settingsService.AzureAdConfig.auth.clientId
            );
        }

        // if not the app's own backend or not a domain listed in the endpoints structure
        return null;
    }
}
