import { IClientSettings } from './../../interfaces/IClientSettings';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { eEnvironment } from '@Workspace/_generated/enums';
import { HttpClient } from '@angular/common/http';
import { MsalAngularConfiguration } from '../auth/msad/msal-angular.configuration';
import { Constants } from '@Workspace/common';
import { Configuration } from '@azure/msal-browser';
import { IApiSettings } from '@Workspace/_generated/interfaces';

@Injectable()
export class SettingsService {
    private clientSettings: IClientSettings;
    private apiSettings: IApiSettings;
    private clientUrl: string;

    constructor(private http: HttpClient, private location: Location) {}

    public get Environment(): eEnvironment {
        return this.apiSettings.environment;
    }

    public get ApiId(): string {
        return this.apiSettings.apiId;
    }

    public get ClientId(): string {
        return this.apiSettings.clientId;
    }

    public get Tenant(): string {
        return this.apiSettings.tenant;
    }

    public get TenantId(): string {
        return this.apiSettings.tenantId;
    }

    public initializeAsync() {
        return new Promise((resolve, reject) => {
            this.initializeUrls();
            
            this.http
                .get(this.createClientUrl(Constants.CLIENT_SETTINGS_URL))
                .subscribe(
                    response => {
                        let data: IClientSettings = response as IClientSettings;
                        this.clientSettings = data;

                        this.http
                            .get(this.createApiUrl(Constants.API_SETTINGS_URL))
                            .subscribe(
                                response => {
                                    this.apiSettings = response as IApiSettings;
                                    resolve(null);
                                },
                                error => {
                                    console.log(error);
                                    resolve(null);
                                }
                            );
                    },
                    error => {
                        console.log(error);
                        reject();
                    }
                );
        });

    }

    public createApiUrl(path: string): string {
        return this.createUrl(this.clientSettings.apiUrl, path);
    }

    public createClientUrl(path: string): string {
        return this.createUrl(this.clientUrl, path);
    }

    public isNotLiveEnvironment(): boolean {
        if (this.apiSettings.environment === eEnvironment.Live.valueOf()) {
            return false;
        }

        return true;
    }

    private createUrl(root: string, path: string): string {
        root = this.getUrl(root);

        if (path.indexOf('/') === 0) {
            path = path.substr(1);
        }

        return root + path;
    }

    private getUrl(url: string): string {
        if (!!url && url.lastIndexOf('/') !== url.length - 1) {
            url = url + '/';
        }

        if (!url) {
            url = '';
        }

        return url;
    }

    private initializeUrls() {
        const angularRoute = this.location.path();
        const url = window.location.origin;

        this.clientUrl = url.replace(angularRoute, '').replace(/#\/?/, '');
    }

    public get AzureAdConfig(): Configuration {
        return {
            auth: {
                clientId: this.ClientId,
                authority: this.Tenant,
                redirectUri: window.location.origin
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false
            }
        };
    }

    public get AzureAdAngularConfig(): MsalAngularConfiguration {
        return {
            consentScopes: ['user.read', `api://${this.ApiId}/api-access`],
            popUp: true,
            protectedResourceMap: new Map([
                [this.clientSettings.apiUrl, [`api://${this.ApiId}/api-access`]]
            ])
        };
    }
}
