import { ToastService } from './../../../../../../../../libs/services/toast/toast.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountController } from '@Workspace/_generated/services';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    form: FormGroup;
    submitted = false;
    loading = false;
    isRequestResetPasswordSuccess = false;
    token: string;

    constructor(
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private accountController: AccountController,
    ) { }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    get f() {
        return this.form.controls;
    }

    onSubmit() {
        this.submitted = true;

        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        
        

        this.accountController.RequestResetPassword({email: this.f.email.value}).subscribe(
            res => {
                this.isRequestResetPasswordSuccess = true;
                this.loading = false;
                this.submitted = false;
                this.initForm();
            },
            err => {
                this.toastService.addHttpError(err);
                this.loading = false;
            }
        )
    }

    getEmailErrors(errors: any) {
        if (errors?.required) return 'Email is required';
        if (errors?.email) return 'Email must be a valid email address';
        return '';
    }
}
