<auth-layout [content]="isResetPasswordSuccess ? resetPasswordSuccess : resetPassword" [isSignInPage]="false"></auth-layout>

<ng-template #resetPassword>
    <div class="login__container">
        <h2>Reset Password</h2>
        <div class="login__description">Please enter your new password</div>
        
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="p-fluid">
                <div class="p-field">
                    <span class="p-float-label">
                        <input id="password" type="password" formControlName="password" [ngClass]="{'ng-dirty': submitted && f.password.errors}" pInputText
                        [tooltipDisabled]="!(f.password.invalid && (f.password.dirty || f.password.touched))" [pTooltip]="getPasswordErrors(f.password.errors)" tooltipPosition="top"/>
                        <label for="password">Password</label>
                    </span>
                </div>
                <div class="p-field">
                    <span class="p-float-label">
                        <input id="confirmPassword" type="password" formControlName="confirmPassword" [ngClass]="{'ng-dirty': submitted && f.confirmPassword.errors}" pInputText
                        [tooltipDisabled]="!(f.confirmPassword.invalid && (f.confirmPassword.dirty || f.confirmPassword.touched))" [pTooltip]="getConfirmPasswordErrors(f.confirmPassword.errors)" tooltipPosition="top"/>
                        <label for="confirmPassword">Confirm Password</label>
                    </span>
                </div>
            </div>
            <button pButton [disabled]="loading" type="submit" label="Submit">
            </button>
        </form>
    </div>
</ng-template>

<ng-template #resetPasswordSuccess>
    <div class="login__container">
        <h2>Password Reset Successful</h2>
        <button pButton type="submit" routerLink="/auth/sign-in" label="Back to Login">
        </button>
    </div>
</ng-template>