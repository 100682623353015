import { Injectable } from '@angular/core';
import { SettingsService } from '@Workspace/services';

import { MsalService } from './msal.service';

@Injectable()
export class MsalWrapperService {
    constructor(
        private settingsService: SettingsService
    ) {}

    public msalService: MsalService;

    public async initializeAsync() {
        if(!this.msalService){
            this.msalService = new MsalService(
                this.settingsService
                );
            }
    }
}
