import { eMessageSeverity } from './../../../../../../../../libs/common/enums';
import { ToastService } from './../../../../../../../../libs/services/toast/toast.service';
import { PageLoaderService } from '@Workspace/services';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@Workspace/auth';
import { ILoginModel, IPreSignInDetailsDto } from '@Workspace/_generated/interfaces';
import { AccountController } from '@Workspace/_generated/services';

@Component({
    selector: 'sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

    form: FormGroup;
    twoStepForm: FormGroup;
    returnUrl: string;
    submitted = false;
    is2FA = false;
    registrationDetails: IPreSignInDetailsDto;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private toastService: ToastService,
        private accountController: AccountController,
        private pageLoaderService: PageLoaderService
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });

        this.twoStepForm = this.formBuilder.group({
            code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[0-9]*')]],
        });

        if (location.hostname === 'localhost') {
            this.f.email.patchValue('admin@bullblock.eu');
            this.f.password.patchValue('');
        }
    }

    get f() {
        return this.form.controls;
    }

    get f2() {
        return this.twoStepForm.controls;
    }

    onSubmit() {
        this.onSubmitAsync();
    }

    async onSubmitAsync() {
        this.pageLoaderService.showPageLoader();
        this.submitted = true;
        
        if (this.form.invalid) {
            return;
        }

        try {
            if(!this.registrationDetails){
                await this.accountController.GetUserPreSignInDetails(<ILoginModel>{ email: this.f.email.value, password: this.f.password.value })
                .toPromise().then(async (data) => {
                    this.registrationDetails = data;
                    await this.handle2FAAsync();
                    
                    this.pageLoaderService.hidePageLoader();
                });
            }else{
                await this.handle2FAAsync();
            } 
        } catch {
            this.toastService.add(
                eMessageSeverity.Error,
                'Login failed',
                'Email or password is incorrect',
            );

            this.pageLoaderService.hidePageLoader();
        }
    }

    async handle2FAAsync(){
            if(this.registrationDetails.is2FAEnabled){
                this.is2FA = true;
                    this.pageLoaderService.hidePageLoader();
            } else {
                await this.authService.login(this.f.email.value, this.f.password.value, "", "")
                .then((data)=> {
                    this.pageLoaderService.hidePageLoader();
                    this.goToDashboardOrUrl();
                });
            }
    }

    async goToDashboardOrUrl() {
        if (!!this.returnUrl) {
            this.router.navigate([this.returnUrl]);
            return;
        }

        this.router.navigate(['/dashboard']);
    }

    onTwoStepSubmit() {
        this.onTwoStepSubmitAsync();
    }

    async onTwoStepSubmitAsync() {
        this.pageLoaderService.showPageLoader();
        this.submitted = true;
        
        if (this.form.invalid) {
            return;
        }

        try {
            await this.authService.login(this.f.email.value, this.f.password.value, this.registrationDetails.secretKey, this.f2.code.value)
            .then((data)=> {
                this.pageLoaderService.hidePageLoader();
                this.goToDashboardOrUrl();
            });
        } catch {
            this.toastService.add(
                eMessageSeverity.Error,
                'Verification failed',
                'Invalid verification code',
            );

            this.pageLoaderService.hidePageLoader();
        }
    }

    getEmailErrors(errors: any) {
        if (errors?.required) return 'Email is required';
        if (errors?.email) return 'Email must be a valid email address';
        return '';
    }

    getPasswordErrors(errors: any) {
        if (errors?.required) return 'Password is required';
        return '';
    }

    getCodeErrors(errors: any) {
        // console.log(errors);
        if (errors?.required) return 'Code is required';
        if (errors?.pattern) return 'Code contains digits only';
        if (!!errors?.minlength || !!errors?.maxlength) return 'Code must be 6 digits';
        return '';
    }
}
